/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-slidedown.accordeon__body {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
}

nobr {
  white-space: nowrap;
  hyphens: none;
}

.ReactCollapse--collapse {
  transition: height 220ms;
}

/* fix смены языка на небольших экранах */
.page-home .header__lang2 {
  display: none;
}

.header__lang2 a {
  font-size: 14px;
  line-height: 20px;
}

.header__lang2 a:hover {
  background: rgba(255, 255, 255, .2);
  color: var(--c-text);
}

.header__lang2 a {
  display: block;
  padding: 4px 6px;
  font-weight: 400;
  background: rgba(255, 255, 255, .1);
  border-radius: 4px;
  color: var(--c-link);
}

.header_mid_hide {
  display: none;
}

@media (max-width:760px) {
  .page-home .header__lang2 {
    display: block;
  }

  .header_mid_hide {
    display: block;
  }
}

.btn.btn-danger {
  background: #cc0c0c;
}

.empty-image {
  background-image: url("../assets/svg/catalog-empty.svg");
  background-repeat: no-repeat;
  border-radius: 4px;
  background-size: contain;
  background-position: center;
}

.popup__container._show .comboInput__label {
  color: var(--c-text-black) !important
}

.popup__back {
  background-color: rgba(0, 0, 0, .7) !important;
}

.popup__back._show {
  opacity: 1 !important;
}

.table-list__item.owner-account {
  background-color: #2569e922;
}

.dashboard__message._badge-warn {
  background: #e7ae33;
  --c-text: var(--c-text-black);
  --c-link: var(--c-text-black);
  --c-link-hover: var(--c-text-black);
}

.dashboard__message._badge-err {
  background: #d66549;
  --c-text: var(--c-text-black);
  --c-link: var(--c-text-black);
  --c-link-hover: var(--c-text-black);
}

.page-producer .content__header-id {
  --c-text: white;
  color: white;
}

.access-admin .footer {
  --c-footer: #F6F2E4 !important;
}

.form__section>ul>li::before {
  display: block;
  content: '' !important;
  position: absolute;
  top: 0;
  left: 0;
}

.permissions-list .table-list__item._active {
  background: inherit !important;
}

.permissions-list__content-body {
  background: inherit !important;
}

.form__section>ul>li {
  margin: 0 0 .5em;
  padding: 0 0 0 0 !important;
}

.page-producer .tv-item__header .content-item__info-item {
  --c-text: var(--c-text-grey);
}

.dashboard__stats-col:first-child {
  -ms-flex: 1 0 315px !important;
  flex: 1 0 315px !important;
}

.footer {
  z-index: 5 !important;
}

.permissions-list__content-body {
	color: inherit !important;
}

.permissions-list .table-list__item._active {
	color: inherit !important;
}

.permissions-list .table-list__item._active .table-list__col._id {
	color: inherit !important;
}